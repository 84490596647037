import { t } from '@lingui/macro';
import React from 'react';
import UserApi from '../../api/UserApi';
import FormComponent from '../../components/FormComponent';
import InternalLayout from '../../components/layouts/InternalLayout';
import { FORM_UPDATE_SUBMIT_BUTTON, TIMEZONE_DEFAULT } from '../../constants';
import { Redirect, useHistory, useParams } from 'react-router';

export const makeUserSchema = ({ title = t`User`, onlyEditUserData = false, hidePassword = false } = {}) => ({
  ':classNames': 'smallTwoRows',
  title,
  'ui:field': 'HideObjectField',
  type: 'object',
  properties: {
    accessProfileId: {
      title: t`User Type`,
      type: 'number',
      lookup: {
        api: 'profiles',
        resource: t`User Types`,
        id: 'id',
        label: 'displayName',
      },
      'ui:field': 'LookupFormField',
      'ui:readonly': onlyEditUserData,
      ':classNames': 'from-col-1',
    },
    firstName: { title: t`First Name`, type: 'string', ':classNames': 'from-col-1' },
    organizationalUnitId: {
      title: t`Organization`,
      type: 'number',
      'ui:field': 'LookupFormField',
      'ui:readonly': onlyEditUserData,
      lookup: {
        api: 'organizationalunits',
        resource: t`Organization`,
        id: 'id',
        label: 'name',
      },
    },
    lastName: { title: t`Last Name`, type: 'string' },
    position: { title: t`Position`, type: ['string', 'null'] },
    email: {
      title: t`email`,
      type: 'string',
      'ui:readonly': onlyEditUserData,
    },
    phone: { title: t`Phone`, type: ['string', 'null'] },
    ...(hidePassword
      ? {}
      : {
          password: {
            title: t`Password`,
            type: 'string',
            ':classNames': 'from-col-1',
            'ui:widget': 'password',
          },
        }),
    extension: {
      title: t`Extension`,
      type: ['string', 'null'],
      ':classNames': hidePassword ? 'from-col-2' : '',
    },
    ...(hidePassword
      ? {}
      : {
          passwordConfirm: {
            title: t`Confirm Password`,
            type: 'string',
            'ui:widget': 'password',
            ':classNames': 'from-col-1',
            'akc:validate': '$isTruthy(password) and password != passwordConfirm ? "es diferente." : null',
          },
        }),
    timeZoneId: {
      title: t`timezone`,
      lookup: {
        api: 'timezones',
        resource: t`timezone`,
        id: 'id',
        label: 'displayName',
        defaultValueExpr: !onlyEditUserData ? { expr: TIMEZONE_DEFAULT } : undefined,
      },
      'ui:field': 'LookupFormField',
      ':classNames': 'from-col-1',
    },
    signature: {
      title: t`Signature`,
      type: ['string', 'null'],
      'ui:field': 'SignatureField',
      ':classNames': 'from-col-1',
    },
    roles: {
      title: t`Roles`,
      ':classNames': 'from-col-1 to-col-3',
      type: 'array',
      'ui:readonly': onlyEditUserData,
      items: {
        type: 'object',
        'ui:addable': !onlyEditUserData,
        'ui:orderable': !onlyEditUserData,
        'ui:removable': !onlyEditUserData,
        properties: {
          roleId: {
            title: t`Role`,
            lookup: {
              api: 'roles',
              resource: t`Roles`,
              id: 'id',
              label: 'name',
            },
            items: { type: 'number' },
            'ui:field': 'LookupFormField',
            'ui:readonly': onlyEditUserData,
          },
          canWorkAs: {
            title: t`Can Work`,
            'ui:cellClassName': 'text-center align-middle',
            type: 'boolean',
          },
          canBeAssigned: {
            title: t`Can be Assigned`,
            'ui:cellClassName': 'text-center align-middle',
            type: 'boolean',
          },
          canAssign: {
            title: t`Can Assign`,
            'ui:cellClassName': 'text-center align-middle',
            type: 'boolean',
          },
          mainRole: {
            title: t`Main Role`,
            'ui:cellClassName': 'text-center align-middle',
            type: 'boolean',
          },
        },
      },
    },
    ...(onlyEditUserData
      ? {}
      : {
          isActive: {
            title: t`Active`,
            type: 'boolean',
            ':classNames': 'from-col-1',
          },
        }),
    isOnLeave: {
      title: t`Out of Office`,
      type: 'boolean',
      ':classNames': onlyEditUserData ? 'from-col-1' : 'from-col-2',
    },
  },
  required: ['firstName', 'lastName', 'email', 'organizationalUnitId', 'accessProfileId', 'position', 'phone', 'timeZoneId', 'signature', 'roles'],
  dependencies: {
    password: ['passwordConfirm'],
  },
});

export const EditUserForm = FormComponent({
  displayName: 'EditUser',
  withoutNavigation: true,
  schema: makeUserSchema(),
  uiSchema: {},
  parseProps: () => ({
    params: useParams(),
    history: useHistory(),
  }),
  loadData: ({ params: { id } }) => UserApi.getUser({ id }),
  async onSubmit(
    { formData },
    {
      props: {
        params: { id },
      },
    }
  ) {
    await UserApi.editUser({ id, ...formData });
  },
  submitButtons: {
    ...FORM_UPDATE_SUBMIT_BUTTON,
    impersonate: {
      text: t`Impersonate`,
      className: 'btn-secondary',
      onBeforeSubmit: async submitEvent => {
        submitEvent.preventDefault();

        const {
          formContext: { auth },
          scope: {
            props: {
              history,
              params: { id: userId },
            },
          },
        } = submitEvent;

        await auth.impersonate({ userId });
        history.push('/');
      },
      'ui:showIf': '$formContext.auth.user.permissions.ImpersonatePeersOrSubordinates',
      ignoreValidation: true,
    },
  },
  renderFormSubmitted: () => <Redirect to="../system-settings/users" />,
});

export default function() {
  return (
    <InternalLayout>
      <EditUserForm />
    </InternalLayout>
  );
}
